// gatsby-browser.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './src/theme';
import './src/styles/main.css'; // Import the global CSS file

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>
  );
};
